import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
const Template = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  const { title, created_at, image_alt, image, image_caption } = frontmatter

  return (
    <Layout>
      <article className="u-mb">
        <h1 className="u-mt+">{title}</h1>
        <div className="c-dark-grey t-uppercase u-pb">{created_at}</div>

        <div className="o-layout">
          <div className="o-layout__item u-1-of-3-tablet u-1-of-1-mobile u-pb">
            <figure className="u-mb0">
              <Img fluid={image.childImageSharp.fluid} alt={image_alt} />
            </figure>
            <figcaption>
              <small>{image_caption}</small>
            </figcaption>
          </div>

          <div
            className="o-layout__item u-2-of-3-tablet u-1-of-1-mobile"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        created_at: date(formatString: "dddd, Do MMMM, YYYY")
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_caption
        image_alt
        updated_at: lastmod
        published
        title
        tags
      }
    }
  }
`

export default Template
